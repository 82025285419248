import { defineStore } from 'pinia';
import { ref } from 'vue';
import {oidc} from './oidc';

export type OidcState = 'SETTING_UP' | 'LOGGED_IN' | 'LOGGED_OUT'

export const useOidcStore = defineStore('oidcStore', () => {
  const state = ref<OidcState>('SETTING_UP');

  const setup = async () => {
    await oidc.tryKeepExistingSessionAsync();
    state.value = oidc.tokens ? 'LOGGED_IN' : 'LOGGED_OUT';
  };
  setup();

  const login = async () => {
    if (state.value !== 'LOGGED_OUT') {
      return;
    }
    sessionStorage.setItem('authentication-callback-redirect', window.location.href);
    await oidc.loginAsync()
  }

  const logout = async () => {
    if (state.value !== 'LOGGED_IN') {
      return;
    }
    await oidc.logoutAsync();
  }

  return {
    state,
    login,
    logout
  }
});
