<script setup lang="ts">
import {computed} from "vue";

import {storeToRefs} from "pinia";
import {useOidcStore} from "../lib/oidcStore";

const {showLoggedIn = false} = defineProps<{ showLoggedIn?: boolean}>();

const oidcStore = useOidcStore();
const {state} = storeToRefs(oidcStore);
const open = computed(() => state.value === "LOGGED_OUT" || (state.value === "LOGGED_IN" && showLoggedIn))
const variant = computed(() => state.value === "LOGGED_OUT" ? "danger" : "primary");

const loginMicrosoft = () => {
  oidcStore.login();
}

const logout = () => {
  oidcStore.logout();
}
</script>

<template>
  <sl-alert
    :open="open"
    :variant="variant"
  >
    <div class="ttsps-auth-alert__container">
      <sl-icon
        slot="icon"
        name="exclamation-octagon"
      />

      <strong
        v-if="state === 'LOGGED_OUT'"
        class="ttsps-auth-alert__message"
      >
        You are not logged in
      </strong>
      <strong
        v-else-if="state === 'LOGGED_IN'"
        class="ttsps-auth-alert__message"
      >
        You are logged in with your Microsoft account.
      </strong>

      <sl-tooltip
        v-if="state === 'LOGGED_OUT'"
        content="Log in with Microsoft"
      >
        <sl-button
          variant="default"
          @click="loginMicrosoft"
        >
          <sl-icon
            slot="prefix"
            name="box-arrow-in-right"
          />
          Log in with Microsoft
        </sl-button>
      </sl-tooltip>

      <sl-tooltip
        v-if="state === 'LOGGED_IN'"
        content="Logout"
      >
        <sl-icon-button
          class="ttsps-auth-alert__logout-icon"
          name="box-arrow-right"
          @click="logout"
        />
      </sl-tooltip>
    </div>
  </sl-alert>
</template>

<style scoped>
.ttsps-auth-alert__container {
  display: flex;
  gap: 12px;
  align-items: center;
}
.ttsps-auth-alert__message {
  flex-grow: 1;
  font-weight: var(--sl-font-weight-semibold);
}
.ttsps-auth-alert__logout-icon {
  color: red
}
</style>